





import { Component, Vue } from "vue-property-decorator";
import Home from "@/views/Home.vue";

Vue.component("home", Home);

@Component
export default class App extends Vue {}
