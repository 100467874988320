























































































import { Component, Vue } from "vue-property-decorator";
import { StoredSystem } from "@/types/System";
@Component
export default class Systems extends Vue {
  showDeleteDialog = false;
  selectedSystem: StoredSystem | null = null;

  data() {
    return {
      showDeleteDialog: this.showDeleteDialog,
      selectedSystem: this.selectedSystem,
      loading: false,
      response: ""
    };
  }
  created() {
    this.refreshSystems();
  }
  refreshSystems() {
    this.$store.dispatch("fetchSystems");
  }
  get systems() {
    // computed property returns the list of systems
    return this.$store.state.systems;
  }
  displayDeleteDialog(system: StoredSystem) {
    this.selectedSystem = system;
    this.showDeleteDialog = true;
  }
  async sendDeleteRequest(system_id: string) {
    const token = await this.$auth.getTokenSilently();
    const response = await fetch(`/api/systems/${system_id}`, {
      headers: new Headers({
        Authorization: `Bearer ${token}`
      }),
      method: "delete"
    });
    return response;
  }
  deleteSystem() {
    if (this.selectedSystem) {
      this.sendDeleteRequest(this.selectedSystem.object_id).then(response => {
        if (response.ok) {
          this.refreshSystems();
        } else {
          console.log(`Failed to delete with code ${response.status}`);
        }
      });
    }
    this.showDeleteDialog = false;
  }
}
