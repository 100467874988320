




































































































import ModelBase from "@/components/ModelBase.vue";

import { Component, Prop, Watch } from "vue-property-decorator";
import {
  SandiaInverterParameters,
  PVWattsInverterParameters
} from "@/types/InverterParameters";

@Component
export default class InverterParametersView extends ModelBase {
  // extend acceptable types for InverterParameters to include a PVWatts class
  @Prop() parameters!: SandiaInverterParameters | PVWattsInverterParameters;
  @Prop() model!: string;
  @Prop({ default: null }) selectedInverter!: string;
  showBrowser = false;

  data() {
    return {
      parameterSource: "User Supplied",
      showBrowser: this.showBrowser
    };
  }

  get apiComponentName() {
    let componentName: string;
    if (this.model == "pvsyst" || this.model == "sam") {
      componentName = "SandiaInverterParameters";
    } else {
      componentName = "PVWattsInverterParameters";
    }
    return componentName;
  }

  @Watch("parameters", { deep: true })
  validate(newParams: Record<string, any>) {
    let params: Record<string, any>;
    if (this.model == "pvsyst" || this.model == "sam") {
      params = newParams as SandiaInverterParameters;
    } else {
      params = newParams as PVWattsInverterParameters;
    }
    this.$validator
      .validate(this.apiComponentName, params)
      .then(this.setValidationResult);
  }
}
