

































import { Component, Vue } from "vue-property-decorator";

@Component
export default class CalculatePRJobParams extends Vue {
  calculate!: string;
  performance_granularity!: string;

  data() {
    return {
      calculate: "weather-adjusted performance ratio",
      performance_granularity: "system"
    };
  }
  mounted() {
    this.emitParams();
  }
  emitParams() {
    this.$emit("new-job-type-params", {
      calculate: this.calculate,
      performance_granularity: this.performance_granularity
    });
  }
}
