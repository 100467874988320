








































































import { Component, Vue, Watch } from "vue-property-decorator";

@Component
export default class CompareJobParams extends Vue {
  compare!: string;
  timeResolution!: string;

  data() {
    return {
      compare: "reference and actual performance",
      timeResolution: "leHourly"
    };
  }
  mounted() {
    this.emitParams();
  }
  emitParams() {
    let params = {
      compare: this.compare
    };
    if (this.containsReference && this.timeResolution == "monthly") {
      params = {
        compare: `monthly ${this.compare}`
      };
    }
    this.$emit("new-job-type-params", params);
  }
  get containsReference() {
    return this.compare.includes("reference");
  }
  @Watch("compare")
  ensureValidTimeResolution() {
    if (!this.containsReference) {
      this.timeResolution = "leHourly";
    }
  }
  @Watch("timeResolution")
  resolutionChange() {
    this.emitParams();
  }
}
