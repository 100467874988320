
















































































import { Component, Vue, Prop, Watch } from "vue-property-decorator";
import { DateTime } from "luxon";

@Component
export default class DatetimeField extends Vue {
  @Prop() timezone!: string;
  @Prop() helpText!: string;
  year!: number;
  month!: number;
  day!: number;
  hour!: number;
  minute!: number;

  data() {
    return {
      year: "",
      month: "",
      day: "",
      hour: "",
      minute: ""
    };
  }
  get currentDate() {
    let current: DateTime | null;
    try {
      current = DateTime.fromObject({
        year: this.year,
        month: this.month,
        day: this.day,
        hour: this.hour,
        minute: this.minute,
        zone: this.timezone
      });
    } catch {
      current = null;
    }
    return current;
  }
  get dayMax() {
    return this.currentDate ? this.currentDate.daysInMonth : 31;
  }
  @Watch("currentDate")
  emitTimeParams() {
    this.$emit("update-datetime", this.currentDate);
  }
  cleanError(errorMessage: string) {
    // remove extra unwanted text from error messages
    return errorMessage.replace(/\(of type .*\)/, "");
  }
}
