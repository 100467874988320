




































































































































































































import { Component, Vue, Prop } from "vue-property-decorator";

@Component
export default class DataParams extends Vue {
  @Prop() dataType!: string;
  @Prop() jobClass!: string;
  data_available!: string;
  weather_granularity!: string;
  irradiance_type!: string;
  temperature_type!: string;
  performance_granularity!: string;

  data() {
    return {
      data_available: "weather only",
      irradiance_type: "standard",
      weather_granularity: "system",
      temperature_type: "air",
      performance_granularity: "system"
    };
  }
  created() {
    if (this.jobClass == "compare") {
      this.data_available = "weather and AC performance";
      if (this.dataType == "reference" || this.dataType == "actual") {
        this.temperature_type = "cell";
        this.irradiance_type = "poa";
      }
    }
  }
  mounted() {
    this.emitParams();
  }
  get parameters() {
    let type: string;
    const extraParameters: Record<string, string> = {};

    if (this.jobClass == "calculate" || this.jobClass == "calculatepr") {
      // calculate use case never includes performance data
      type = "data_parameters";
    } else {
      if (this.dataType == "reference") {
        // Compare use cases using reference data require the user to declare
        // if their data includes performance data, if it does they are expected to
        // provide the granularity of that data
        type = "reference_data_parameters";
        extraParameters.data_available = this.data_available;
        if (this.data_available.includes("performance")) {
          extraParameters.performance_granularity = this.performance_granularity;
        }
      } else {
        if (this.dataType == "all_data") {
          type = "data_parameters";
        } else {
          type = `${this.dataType}_data_parameters`;
        }
      }
    }
    const params: Record<string, string | Record<string, string>> = {
      type: type,
      parameters: {
        irradiance_type: this.irradiance_type,
        weather_granularity: this.weather_granularity,
        temperature_type: this.temperature_type,
        ...extraParameters
      }
    };
    if (
      this.data_available.includes("performance") &&
      this.dataType != "modeled"
    ) {
      // @ts-expect-error
      params.parameters[
        "performance_granularity"
      ] = this.performance_granularity;
    }
    return params;
  }
  emitParams() {
    this.$emit("new-data-params", this.parameters);
  }
}
