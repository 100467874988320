






































































































import { Component, Prop, Watch } from "vue-property-decorator";
import SurfaceTypes from "@/constants/surface_albedo.json";
import { PVArray } from "@/types/PVArray";
import {
  PVSystModuleParameters,
  PVWattsModuleParameters,
  CECModuleParameters
} from "@/types/ModuleParameters";

import {
  PVSystTemperatureParameters,
  SAPMTemperatureParameters,
  NOCTSAMTemperatureParameters
} from "@/types/TemperatureParameters";

import {
  FixedTrackingParameters,
  SingleAxisTrackingParameters
} from "@/types/Tracking";

import ModelBase from "@/components/ModelBase.vue";

interface HTMLInputEvent extends Event {
  target: HTMLInputElement & EventTarget;
}

@Component
export default class ArrayView extends ModelBase {
  @Prop() parameters!: PVArray;
  @Prop() index!: number;
  @Prop() model!: string;
  @Prop() allFixed!: boolean;
  @Prop() numArrays!: number;

  tracking!: string;
  surfaceTypes: Record<string, number> = SurfaceTypes;

  data() {
    return {
      tracking: this.inferTracking()
    };
  }
  created() {
    this.tracking = this.inferTracking();
  }
  inferTracking() {
    if (FixedTrackingParameters.isInstance(this.parameters.tracking)) {
      return "fixed";
    } else {
      return "singleAxis";
    }
  }
  @Watch("model")
  changeModel(newModel: string) {
    if (newModel == "pvsyst") {
      this.parameters.module_parameters = new PVSystModuleParameters({});
      this.parameters.temperature_model_parameters = new PVSystTemperatureParameters(
        {}
      );
    } else if (newModel == "sam") {
      this.parameters.module_parameters = new CECModuleParameters({});
      this.parameters.temperature_model_parameters = new NOCTSAMTemperatureParameters(
        {}
      );
    } else if (newModel == "pvwatts") {
      this.parameters.module_parameters = new PVWattsModuleParameters({});
      this.parameters.temperature_model_parameters = new SAPMTemperatureParameters(
        {}
      );
    }
  }

  changeAlbedo(e: HTMLInputEvent) {
    this.parameters.albedo = this.surfaceTypes[e.target.value];
  }

  changeTracking(e: HTMLInputEvent) {
    const tracking = e.target.value;
    if (tracking == "fixed") {
      this.parameters.tracking = new FixedTrackingParameters({});
    } else {
      this.parameters.tracking = new SingleAxisTrackingParameters({});
    }
  }
  @Watch("parameters.tracking")
  ensureTracking() {
    // Ensure tracking stays consistent when the tracking parameters change
    // commonly fired an array is removed, so tha the component updates with
    // the new object. This does not trigger when fields on the tracking
    // object are changed.
    this.tracking = this.inferTracking();
  }

  removeArray() {
    this.$emit("array-removed", this.index);
  }
  duplicateArray() {
    this.$emit("array-added", this.parameters);
  }
  get apiComponentName() {
    return "PVArray";
  }

  @Watch("parameters", { deep: true })
  validate(newArray: Record<string, any>) {
    const arr = newArray as PVArray;
    this.$validator
      .validate(this.apiComponentName, arr)
      .then(this.setValidationResult);
  }
  loadModuleParameters({
    parameters,
    name
  }: {
    parameters: Record<string, any>;
    name: string;
  }) {
    this.parameters.make_model = name;
    this.parameters.module_parameters = new CECModuleParameters(parameters);
  }
}
