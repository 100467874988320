





















import { Component, Vue } from "vue-property-decorator";

interface HTMLInputEvent extends Event {
  target: HTMLInputElement & EventTarget;
}

@Component
export default class FileUpload extends Vue {
  errors!: Array<string>;
  isLoading!: boolean;

  data() {
    return {
      isLoading: false,
      errors: []
    };
  }

  setLoading(fileStatus: boolean) {
    this.isLoading = fileStatus;
  }

  processFile(e: HTMLInputEvent) {
    this.setLoading(true);

    if (e.target.files !== null) {
      const fileList = e.target.files;
      if (fileList.length > 1) {
        this.errors.push("Can only accept one file at a time.");
      } else {
        const file = e.target.files[0];
        const reader = new FileReader();

        // Typescript complains about f possibly being null, cant use a regular
        // function becasue need access to this.
        // @ts-expect-error
        reader.onload = f => this.$emit("uploadSuccess", f.target.result);
        reader.readAsText(file);

        this.errors = [];
      }
    }
    this.setLoading(false);
  }
}
