var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"systems"},[_c('h1',[_vm._v("Systems")]),_c('router-link',{attrs:{"to":{ name: 'Model' }}},[_vm._v("Create new System")]),_c('br'),(_vm.loading)?_c('p',[_vm._v("Loading...")]):_vm._e(),(!_vm.loading)?_c('div',{staticClass:"container"},[_c('ul',{staticClass:"grid"},[_vm._m(0),(_vm.systems.length == 0)?_c('li',[_vm._v("No available systems")]):_vm._e(),_vm._l((_vm.systems),function(system,uuid){return _c('li',{key:uuid},[_c('span',{staticClass:"system-cell system-name"},[_c('b',[_vm._v(_vm._s(system.definition.name))])]),_c('span',{staticClass:"system-cell small"},[_c('router-link',{attrs:{"to":{ name: 'Update System', params: { systemId: uuid } }}},[_vm._v(" Edit ")])],1),_c('span',{staticClass:"system-cell small"},[_c('a',{staticClass:"delete-button",attrs:{"role":"button"},on:{"click":function($event){return _vm.displayDeleteDialog(system)}}},[_vm._v(" Delete ")])]),_c('span',{staticClass:"system-cell"},[_c('router-link',{attrs:{"to":{
              name: 'Calculate Performance',
              params: { systemId: uuid }
            },"tag":"button"}},[_vm._v(" Calculate Performance ")])],1),_c('span',{staticClass:"system-cell"},[_c('router-link',{attrs:{"to":{
              name: 'Compare Performance',
              params: { systemId: uuid }
            },"tag":"button"}},[_vm._v(" Compare Performance ")])],1),_c('span',{staticClass:"system-cell"},[_c('router-link',{attrs:{"to":{
              name: 'Calculate Performance Ratio',
              params: { systemId: uuid }
            },"tag":"button","disabled":""}},[_vm._v(" Calculate PR ")])],1)])})],2)]):_vm._e(),_c('transition',{attrs:{"name":"fade"}},[(_vm.showDeleteDialog)?_c('div',{attrs:{"id":"delete-dialog"}},[_c('div',{staticClass:"modal-block"},[_c('p',[_vm._v(" Are you sure you want to delete the system "+_vm._s(_vm.selectedSystem.definition.name)+"? ")]),_c('button',{staticClass:"confirm-deletion",on:{"click":_vm.deleteSystem}},[_vm._v("Yes")]),_c('button',{staticClass:"cancel-deletion",on:{"click":function($event){_vm.showDeleteDialog = false}}},[_vm._v(" Cancel ")])])]):_vm._e()])],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('li',[_c('span',{staticClass:"system-cell"},[_c('b',[_vm._v("Name")])])])}]

export { render, staticRenderFns }