































































































import { Component, Prop, Vue } from "vue-property-decorator";
import { getIndex } from "@/utils/fieldIndex";

@Component
export default class ModelField extends Vue {
  @Prop() fieldName!: string;
  @Prop() parameters!: Record<string, any>;
  @Prop() definitions!: Record<string, any>;
  @Prop() errors!: Record<string, any>;
  fieldIndex!: number;

  data() {
    return {
      fieldIndex: getIndex()
    };
  }

  get title() {
    return this.definitions.properties[this.fieldName].title;
  }
  get inputType() {
    return this.definitions.properties[this.fieldName].type;
  }
  get fieldId() {
    return `field_${this.fieldIndex}`;
  }
  get titleId() {
    return `${this.fieldId}_title`;
  }
  get helpId() {
    return `${this.fieldId}_help`;
  }
  emitChange(event: any) {
    this.$emit("change", event);
  }
}
