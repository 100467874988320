

























































































import { Component, Vue, Prop, Watch } from "vue-property-decorator";
import { getVariableDisplayName } from "@/utils/displayNames";

@Component
export default class CSVPreview extends Vue {
  @Prop() mapping!: Record<string, string>;
  @Prop() currentlySelected!: string;
  @Prop() headers!: Array<string>;
  @Prop() csvData!: Array<Record<string, any>>;

  get numCol() {
    return this.headers.length + 1;
  }
  @Watch("mapping", { deep: true })
  rerender() {
    this.$forceUpdate();
  }
  displayName(variable: string) {
    return getVariableDisplayName(variable);
  }
}
