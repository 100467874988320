





































import { Component, Vue } from "vue-property-decorator";

@Component
export default class CalculateJobParams extends Vue {
  calculate!: string;

  data() {
    return {
      calculate: "reference performance"
    };
  }
  mounted() {
    this.emitParams();
  }
  emitParams() {
    this.$emit("new-job-type-params", { calculate: this.calculate });
  }
}
