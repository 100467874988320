



































































import ModelBase from "@/components/ModelBase.vue";
import { Component, Prop, Watch } from "vue-property-decorator";

// Update with many classes of inverter parameters to check for type before
// choosing a display.
import { PVWattsLosses } from "@/types/Losses";

@Component
export default class LossParametersView extends ModelBase {
  @Prop() parameters!: PVWattsLosses | null;

  @Prop() model!: string;
  errors: Record<string, any> = {};

  get apiComponentName() {
    return "PVWattsLosses";
  }
  @Watch("parameters", { deep: true })
  validate(newParams: Record<string, any>) {
    if (this.model == "pvwatts") {
      this.$validator
        .validate(this.apiComponentName, newParams as PVWattsLosses)
        .then(this.setValidationResult);
    }
  }
}
