





















































































import { Component, Vue, Prop } from "vue-property-decorator";
import MultiTimeseriesPlots from "@/components/jobs/data/MultiTimeSeries.vue";

import * as Jobs from "@/api/jobs";
import { Table } from "apache-arrow";
import { getIndex } from "@/utils/fieldIndex";
import { getVariableDisplayName } from "@/utils/displayNames";
import { getVariableUnits } from "@/utils/units";
import { getUnitConverter, getUnitOptions } from "@/utils/unitConversion";

Vue.component("multi-plot", MultiTimeseriesPlots);

@Component
export default class CustomPlots extends Vue {
  @Prop() job!: Record<string, any>;
  @Prop() resultObjects!: Array<Record<string, any>>;

  // Acutal API data objects to handle
  toPlot!: Record<string, any>;

  // component control properties
  dataSources!: Record<number, string>;
  selectedObjects!: Record<number, Record<string, any>>;
  loadedTimeseries!: Record<string, any>;
  loadingData!: boolean;
  variables!: Record<number, Array<string>>;
  selectedVariables!: Record<string, string>;
  units!: Record<number, string>;

  plotData!: Record<number, any>;

  data() {
    return {
      dataSources: {},
      selectedObjects: {},
      selectedVariables: {},
      loadedTimeseries: {},
      loadingData: false,
      variables: {},
      toPlot: {},
      plotData: {},
      units: {}
    };
  }
  addToPlot(key: number) {
    const data_object_id = this.selectedObjects[key].object_id;
    const dataId = data_object_id + this.selectedVariables[key];
    const column = this.loadedTimeseries[data_object_id].getColumn(
      this.selectedVariables[key]
    );
    let columnData: Array<any>;
    const firstValue = column.get(0);
    // get a converter function if necessary
    const converter = getUnitConverter(
      getVariableUnits(this.selectedVariables[key]),
      this.units[key]
    );
    if (firstValue && firstValue.length && firstValue.length > 1) {
      // Handles values like int64 which are encoded as length 2 int32 array
      columnData = [];
      for (let i = 0; i < column.length; i++) {
        if (converter) {
          columnData.push(converter(parseFloat(column.get(i))));
        } else {
          columnData.push(parseFloat(column.get(i)));
        }
      }
    } else {
      columnData = column.toArray();
      if (converter) {
        columnData = columnData.map(converter);
      }
    }
    this.$set(this.plotData[key], dataId, {
      data: columnData,
      index: this.loadedTimeseries[data_object_id].getColumn("time"),
      units: this.units[key],
      name: this.currentName(key)
    });
  }
  removeFromPlot(key: number, dataId: string) {
    this.$delete(this.plotData[key], dataId);
  }
  createPlot() {
    const index = getIndex();
    this.dataSources[index] = "results";
    this.$set(this.selectedVariables, index, "");
    this.setDataOption(index);
    this.$set(this.plotData, index, {});
  }
  removePlot(index: number) {
    this.$delete(this.plotData, index);
  }
  filteredObjects(toFilter: Array<Record<string, any>>) {
    // Filter out summary data
    return toFilter.filter(x => {
      const dataType = x.definition.type;
      return !(
        dataType.includes(" vs ") ||
        dataType.includes("summary") ||
        dataType.includes("flag")
      );
    });
  }
  get dataObjects() {
    return this.job.data_objects;
  }
  dataOptions(key: number) {
    if (this.dataSources[key] == "results") {
      return this.filteredObjects(this.resultObjects);
    } else {
      return this.filteredObjects(this.dataObjects);
    }
  }
  async loadData(key: number) {
    let fetchFunc = Jobs.getData;

    if (this.dataSources[key] == "results") {
      fetchFunc = Jobs.getSingleResult;
    }
    const token = await this.$auth.getTokenSilently();
    const object_id = this.selectedObjects[key].object_id;

    const response = await fetchFunc(
      token,
      this.job.object_id,
      object_id
    ).then(payload => payload.arrayBuffer());

    return Table.from([new Uint8Array(response)]);
  }
  setVariables(key: number) {
    const object_id = this.selectedObjects[key].object_id;
    let data = [];
    if (this.loadedTimeseries[object_id]) {
      data = this.loadedTimeseries[object_id].schema.fields
        .map((x: any) => x.name)
        .filter((y: string) => y != "time" && y != "month");
    }
    this.$set(this.variables, key, data);
  }
  get timezone() {
    return this.job.definition.parameters.time_parameters.timezone;
  }

  get convertableVariables() {
    const convertable: Array<string> = [];
    for (const index in this.selectedVariables) {
      const variable = this.selectedVariables[index];
      // @ts-expect-error
      convertable[index] = ["W", "Wh", "W/m^2", "Wh/m^2"].includes(
        getVariableUnits(variable)
      );
    }
    return convertable;
  }

  currentName(key: number) {
    let source = "Uploaded";
    if (this.dataSources[key] == "results") {
      source = "Calculated";
    }
    const dataType = this.cleanDataType(
      this.selectedObjects[key].definition.type
    );
    const varName = getVariableDisplayName(this.selectedVariables[key]);
    const units = this.units[key];
    return `${source} ${dataType} ${varName} [${units}]`;
  }
  updateData(key: number) {
    this.$set(this.selectedVariables, key, "");
    if (this.selectedObjects[key]) {
      const object_id = this.selectedObjects[key].object_id;
      if (!(object_id in this.loadedTimeseries)) {
        this.loadingData = true;
        this.loadData(key).then(data => {
          this.loadedTimeseries[object_id] = data;
          this.setVariables(key);
          this.selectedVariables[key] = "";
          this.loadingData = false;
        });
      } else {
        this.setVariables(key);
        this.selectedVariables[key] = "";
      }
    }
  }
  variableName(varName: string) {
    return getVariableDisplayName(varName);
  }
  setDataOption(key: number) {
    this.selectedObjects[key] = this.dataOptions(key)[0];
    this.updateData(key);
  }
  setUnits(key: number) {
    const variable = this.selectedVariables[key];
    if (variable && variable != "") {
      this.units[key] = getVariableUnits(variable);
    }
  }
  getUnitOptions(variable: string) {
    return getUnitOptions(variable);
  }
  cleanDataType(dataType: string) {
    // strip out unwanted redundant data type information
    if (dataType.includes("performance data")) {
      return dataType.replace(" performance data", "");
    }
    if (dataType == "weather adjusted performance") {
      return "weather adjusted reference";
    }
    return dataType;
  }
}
