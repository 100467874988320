

































































import { Component, Vue, Prop } from "vue-property-decorator";
import DatetimeField from "@/components/jobs/parameters/DatetimeField.vue";
import Timezones from "@/constants/timezones.json";
import { DateTime, LocalZone } from "luxon";

Vue.component("datetimefield", DatetimeField);

@Component
export default class JobTimeParameters extends Vue {
  @Prop() timeparams!: Record<string, any>;
  start!: string | null;
  end!: string | null;
  timezone!: string;
  step!: number;
  timezoneList: Array<string> = Timezones;
  errors!: Record<string, string>;

  data() {
    const zone = new LocalZone().name;
    return {
      errors: {},
      start: null,
      end: null,
      step: 60,
      timezone: zone
    };
  }
  setDataFields({
    start = null,
    end = null,
    step = 3600,
    timezone = new LocalZone().name
  }) {
    this.start = start;
    this.end = end;
    this.step = step / 60;
    this.timezone = timezone;
  }
  mounted() {
    if (this.timeparams) {
      this.setDataFields(this.timeparams);
    }
    this.emitParams();
  }
  emitParams() {
    if (this.start && this.end && this.start >= this.end) {
      this.$set(this.errors, "Start End", "Start must be before End.");
    } else {
      const timeParams = {
        start: this.start,
        end: this.end,
        step: this.step * 60,
        timezone: this.timezone
      };
      this.$delete(this.errors, "Start End");
      this.$emit("new-timeparams", timeParams);
    }
  }
  setStart(newStart: DateTime | null) {
    this.start = newStart ? newStart.toISO() : null;
    this.emitParams();
  }
  setEnd(newEnd: DateTime | null) {
    this.end = newEnd ? newEnd.toISO() : null;
    this.emitParams();
  }
}
