











































































import { Component, Vue, Prop } from "vue-property-decorator";
import { System } from "@/types/System";
import * as Jobs from "@/api/jobs";

import CalculateJobParams from "@/components/jobs/parameters/CalculateJobParams.vue";
import CompareJobParams from "@/components/jobs/parameters/CompareJobParams.vue";
import CalculatePRJobParams from "@/components/jobs/parameters/CalculatePRJobParams.vue";
import DataParamHandler from "@/components/jobs/parameters/DataParamHandler.vue";

import APIErrors from "@/components/ErrorRenderer.vue";

Vue.component("calculate-job-params", CalculateJobParams);
Vue.component("compare-job-params", CompareJobParams);
Vue.component("calculatepr-job-params", CalculatePRJobParams);
Vue.component("api-errors", APIErrors);
Vue.component("data-param-handler", DataParamHandler);

@Component
export default class JobParameters extends Vue {
  @Prop() systemId!: string;
  @Prop() system!: System;
  @Prop() jobClass!: string;

  errorFields = ["time_parameters"];

  jobTypeParams!: Record<string, string>;
  dataParams!: Record<string, any>;
  jobSubmitted!: boolean;
  jobId!: string;

  apiErrors!: Array<Record<string, any>>;
  errorState!: boolean;
  timeParams!: Record<string, any>;
  jobSetupComponent!: string;

  data() {
    return {
      jobTypeParams: {},
      jobSubmitted: false,
      apiErrors: [],
      errorState: false,
      jobId: null,
      timeParams: {},
      dataParams: {}
    };
  }
  storeTimeParams(timeParams: Record<string, any>) {
    this.timeParams = timeParams;
  }
  get jobSpec() {
    if (this.isMonthly) {
      return {
        system_id: this.systemId,
        ...this.jobTypeParams
      };
    } else {
      return {
        system_id: this.systemId,
        time_parameters: this.timeParams,
        ...this.jobTypeParams,
        ...this.dataParams
      };
    }
  }
  get isValid() {
    // check that the start/end have been set, as they default to null
    return Boolean(
      this.isMonthly ||
        // @ts-expect-error
        (this.jobSpec.time_parameters.start && this.jobSpec.time_parameters.end)
    );
  }
  async postJob() {
    const token = await this.$auth.getTokenSilently();
    const response = await Jobs.create(token, this.jobSpec);
    if (response.ok) {
      const responseBody = await response.json();
      this.jobId = responseBody.object_id;
      this.jobSubmitted = true;
      this.$emit("job-created", this.jobId);
    } else {
      if (response.status == 422) {
        const responseBody = await response.json();
        this.apiErrors = responseBody.detail;
      } else {
        this.apiErrors = [
          {
            loc: ["error"],
            msg: `Failed to start job with error code ${response.status}`,
            type: "error"
          }
        ];
      }
      this.errorState = true;
    }
  }
  submitJob(e: any) {
    this.postJob();
    e.preventDefault();
  }

  get jobParamComponent() {
    return `${this.jobClass}-job-params`;
  }

  setJobTypeParams(newParams: Record<string, string>) {
    this.jobTypeParams = newParams;
  }

  setDataParams(parameters: Record<string, any>) {
    this.dataParams = parameters;
  }

  get isMonthly() {
    return (
      "compare" in this.jobTypeParams &&
      this.jobTypeParams["compare"].includes("monthly")
    );
  }
}
