







































































import { Component, Vue, Prop, Watch } from "vue-property-decorator";
import { System } from "@/types/System";
import { Inverter } from "@/types/Inverter";
import { PVArray } from "@/types/PVArray";
import { getVariableDisplayName } from "@/utils/displayNames";
import { getVariableUnits } from "@/utils/units";
import { CSVHeader } from "@/utils/mapToCSV";

import SingleMapping from "@/components/jobs/SingleMapping.vue";

interface HTMLInputEvent extends Event {
  target: HTMLInputElement & EventTarget;
}

interface MetadataWithDataObject {
  data_object: Record<string, any>;
  metadata: System | Inverter | PVArray;
}

type SystemComponent = System | Inverter | PVArray;

Vue.component("single-mapping", SingleMapping);

@Component
export default class FieldMapper extends Vue {
  @Prop() headers!: Array<CSVHeader>;
  @Prop() usedHeaders!: Array<number>;
  @Prop() comp!: MetadataWithDataObject;
  @Prop() show!: boolean;
  @Prop() indexField!: string;
  mapping!: Record<string, Record<string, string>>;

  data() {
    return {
      mapping: {}
    };
  }
  get metadata() {
    return this.comp.metadata;
  }
  get required() {
    return this.comp.data_object.definition.data_columns.filter(
      (x: string) => x != this.indexField
    );
  }
  emitMapping() {
    this.$emit("mapping-updated", {
      ...this.mapping,
      loc: this.comp.data_object.definition.schema_path
    });
  }
  addMapping(mapping: Record<string, string>) {
    const variable = mapping.variable;
    delete mapping.variable;

    // If the variable is already in the mapping, free the header it is
    // currently mapped to.
    if (variable in this.mapping) {
      this.$emit("free-header", this.mapping[variable].csv_header);
    }
    if (mapping.csv_header == "Not included") {
      // unmap the variable if not included
      delete this.mapping[variable];
    } else {
      // Update mapping and emit an event using the header.
      this.mapping[variable] = mapping;
      this.$emit("used-header", mapping.csv_header);
    }
    this.emitMapping();
  }
  getDisplayName(variable: string) {
    let variableName: string;
    if (variable == "effective_irradiance") {
      variableName = "Effective Irradiance or Plane of Array Global Irradiance";
    } else {
      variableName = getVariableDisplayName(variable);
    }
    const units = getVariableUnits(variable);
    let displayName = variableName;
    if (units) {
      displayName += ` [${units}]`;
    }
    return displayName;
  }
  isValid() {
    return this.required.every((x: string) => x in this.mapping);
  }
  @Watch("headers")
  reset() {
    this.mapping = {};
  }
}
