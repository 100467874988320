










































import { Component, Vue } from "vue-property-decorator";

@Component
export default class Home extends Vue {
  data() {
    return {
      systems: this.$store.state.systems
    };
  }
  login() {
    this.$auth.loginWithRedirect();
  }
  logout() {
    this.$auth.logout({
      returnTo: window.location.origin
    });
  }
}
