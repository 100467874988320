
























import { Component, Prop, Vue } from "vue-property-decorator";
import { Inverter } from "@/types/Inverter";
import {
  PVWattsInverterParameters,
  SandiaInverterParameters
} from "@/types/InverterParameters";
import { PVWattsLosses } from "@/types/Losses";

@Component
export default class InvertersView extends Vue {
  @Prop() inverters!: Array<Inverter>;
  @Prop() model!: string;

  addInverter(existingInverter: Inverter | null) {
    let newInverter: Inverter;
    if (existingInverter) {
      newInverter = new Inverter({
        ...existingInverter,
        name: `${existingInverter.name} copy`
      });
    } else {
      let paramClass: any = PVWattsInverterParameters;
      let lossClass: any = PVWattsLosses;
      if (this.model == "pvsyst" || this.model == "sam") {
        paramClass = SandiaInverterParameters;
        lossClass = null;
      }
      newInverter = new Inverter({
        name: `Inverter ${this.inverters.length + 1}`,
        inverter_parameters: new paramClass({}),
        losses: lossClass ? new lossClass({}) : null
      });
    }
    this.inverters.push(newInverter);
  }
  removeInverter(index: number) {
    this.inverters.splice(index, 1);
  }
}
