




























































































import ModelBase from "@/components/ModelBase.vue";
import DefaultParams from "@/constants/temp_params.json";
import { Component, Prop, Watch } from "vue-property-decorator";

// Update with many classes of inverter parameters to check for type before
// choosing a display.
import {
  PVSystTemperatureParameters,
  SAPMTemperatureParameters
} from "@/types/TemperatureParameters";

@Component
export default class TemperatureParametersView extends ModelBase {
  @Prop() parameters!: PVSystTemperatureParameters | SAPMTemperatureParameters;

  @Prop() model!: string;
  errors: Record<string, any> = {};
  defaultMap: Record<
    string,
    Record<
      string,
      Partial<PVSystTemperatureParameters> | SAPMTemperatureParameters
    >
  > = DefaultParams;

  get apiComponentName() {
    let componentName: string;
    if (this.model == "pvsyst") {
      componentName = "PVsystTemperatureParameters";
    } else if (this.model == "sam") {
      componentName = "NOCTSAMTemperatureParameters";
    } else {
      componentName = "SAPMTemperatureParameters";
    }
    return componentName;
  }

  @Watch("parameters", { deep: true })
  validate(newParams: Record<string, any>) {
    this.$validator
      .validate(this.apiComponentName, newParams)
      .then(this.setValidationResult);
  }

  get mountOptions() {
    let params: Array<string>;
    if (this.model == "pvsyst" || this.model == "sam") {
      params = Object.keys(this.defaultMap.pvsyst);
    } else {
      params = Object.keys(this.defaultMap.sapm);
    }
    return params;
  }
  mounting(event: any) {
    const mountOpt = event.target.value;
    let newvals;
    // Empty string indicates custom input
    if (mountOpt) {
      if (this.model == "pvsyst" || this.model == "sam") {
        newvals = this.defaultMap.pvsyst[mountOpt];
      } else {
        newvals = this.defaultMap.sapm[mountOpt];
      }
      this.parameters = Object.assign(this.parameters, newvals);
    }
  }
}
