






































































































































































import { Component, Prop, Watch } from "vue-property-decorator";

import ModelBase from "@/components/ModelBase.vue";

import {
  PVSystModuleParameters,
  PVWattsModuleParameters
} from "@/types/ModuleParameters";

@Component
export default class ModuleParametersView extends ModelBase {
  @Prop() parameters!: PVSystModuleParameters | PVWattsModuleParameters;
  @Prop({ default: "pvsyst" }) model!: string;
  showBrowser!: boolean;

  data() {
    return {
      showBrowser: false
    };
  }

  get apiComponentName() {
    if (this.model == "pvsyst") {
      return "PVsystModuleParameters";
    } else if (this.model == "sam") {
      return "CECModuleParameters";
    } else {
      return "PVWattsModuleParameters";
    }
  }

  @Watch("parameters", { deep: true })
  validate(newParams: Record<string, any>) {
    let params: Record<string, any>;
    if (this.model == "pvsyst") {
      params = newParams as PVSystModuleParameters;
    } else {
      params = newParams as PVWattsModuleParameters;
    }
    this.$validator
      .validate(this.apiComponentName, params)
      .then(this.setValidationResult);
  }
}
