



















































































































































































































































import { Component, Vue, Prop } from "vue-property-decorator";
import JobParams from "@/components/jobs/parameters/JobParams.vue";
import JobResults from "@/components/jobs/JobResults.vue";
import TimeseriesTable from "@/components/jobs/data/TimeseriesResultsTable.vue";
import { StoredSystem, System } from "@/types/System";
import * as Jobs from "@/api/jobs";

Vue.component("job-params", JobParams);
Vue.component("job-results", JobResults);
Vue.component("timeseries-table", TimeseriesTable);

@Component
export default class JobHandler extends Vue {
  @Prop() typeOfJob!: string;
  @Prop() jobId!: string;
  @Prop() systemId!: string;
  loading!: boolean;
  job!: Record<string, any>;
  system!: System;
  step!: string;
  errorState!: boolean;
  errors!: Record<string, any>;

  created() {
    if (this.jobId) {
      this.loadJob();
    } else {
      if (this.systemId) {
        this.loadSystem();
      }
    }
    this.setStep();
  }
  data() {
    return {
      job: null,
      loading: true,
      step: null,
      errorState: false,
      errors: {},
      system: null
    };
  }

  loadCreatedJob(jobId: string) {
    // Callback for when setup completes. Pushes the new route of /jobs/<jobId>
    // which sets the jobId prop. Then, load the job from the api to get the
    // created data objects etc.
    this.$router.push({
      name: "Job View",
      params: { jobId: jobId }
    });
    this.loadJob();
  }
  async fetchJob() {
    const token = await this.$auth.getTokenSilently();
    const response = await Jobs.read(token, this.jobId);
    if (response.ok) {
      this.job = await response.json();
      this.system = this.job.definition.system_definition;
    } else {
      this.errorState = true;
      this.errors = {
        error: "Job not found."
      };
    }
  }
  async loadJob() {
    await this.fetchJob();
    this.loading = false;
    this.setStep();
  }

  setStep() {
    if (this.job) {
      if (this.jobStatus == "incomplete") {
        // Set current step to first job data type missing any data
        const dataStatus = this.dataStepStatus;
        let theStep = Object.keys(dataStatus)[0];
        for (const dataStep in dataStatus) {
          if (!(dataStatus[dataStep] == "Complete")) {
            theStep = dataStep;
            break;
          }
        }
        this.step = theStep;
      } else if (this.jobStatus == "prepared") {
        this.step = "results";
      } else {
        this.step = "results";
      }
    } else {
      this.step = "setup";
    }
  }
  get jobClass() {
    // Returns a generic job type of 'calculate', 'compare' or 'calculatepr'
    if (this.job) {
      if ("compare" in this.jobParameters) {
        return "compare";
      } else {
        if (
          this.jobParameters.calculate == "weather-adjusted performance ratio"
        ) {
          return "calculatepr";
        } else {
          return "calculate";
        }
      }
    } else {
      // Expect that the job type was passed as a prop
      return this.typeOfJob;
    }
  }

  get dataObjects() {
    return this.job.data_objects;
  }

  get jobParameters() {
    const params = this.job.definition.parameters;
    return params;
  }

  get jobStatus() {
    if (this.job) {
      return this.job.status.status;
    } else {
      return null;
    }
  }

  get dataSteps() {
    if (this.job) {
      return this.dataObjects
        .map((x: any) => {
          return x.definition.type;
        })
        .filter((v: string, i: number, self: Array<string>) => {
          return self.indexOf(v) === i;
        });
    }
    return [];
  }

  get dataStepStatus() {
    // Returns an object with keys of data_object types and values of status
    // strings for printing in the template above
    const dataStatus: Record<string, string> = {};
    for (const dataStep of this.dataSteps) {
      dataStatus[dataStep] = this.dataObjectStatus(dataStep);
    }
    return dataStatus;
  }

  filteredDataObjects(jobDataType = "any") {
    // Returns the data objects for the job with the type of `jobDataType`.
    // Special `any` value returns all data objects
    if (jobDataType != "any") {
      return this.dataObjects.filter(
        (x: any) => x.definition.type == jobDataType
      );
    }
    return this.dataObjects;
  }

  dataObjectsPresent(jobDataType = "any") {
    // Checks if all data objects with `type` of jobDataType are present
    return this.filteredDataObjects(jobDataType).every(
      (obj: any) => obj.definition.present
    );
  }

  dataObjectStatus(jobDataType = "any") {
    // Returns a status to display for the given job data type
    if (this.job) {
      if (this.dataObjectsPresent(jobDataType)) {
        return "Complete";
      } else {
        return "Needs data";
      }
    } else {
      return "Calculation Setup Required";
    }
  }

  get jobSteps() {
    let steps = ["setup"];
    if (this.job) {
      // Add in data steps
      steps = steps.concat(this.dataSteps);
      steps = steps.concat(["results"]);
    }
    return steps;
  }

  get setupStatus() {
    if (this.job) {
      return "Complete";
    } else {
      return "Requires setup";
    }
  }

  get resultsStatus() {
    if (this.job) {
      if (this.jobStatus == "running") {
        return "Running";
      } else if (this.jobStatus == "complete") {
        return "Ready";
      } else if (this.jobStatus == "queued") {
        return "Queued";
      } else if (this.jobStatus == "error") {
        return "An error occurred";
      } else {
        return "Data Required";
      }
    } else {
      return "Calculation Setup Required";
    }
  }
  handleData() {
    // reload the job to get current state of data-objects
    this.loadJob();
  }
  async loadSystem() {
    const token = await this.$auth.getTokenSilently();
    const response = await fetch(`/api/systems/${this.systemId}`, {
      headers: new Headers({
        Authorization: `Bearer ${token}`
      })
    });
    if (response.ok) {
      const system = await response.json();
      this.system = new StoredSystem(system).definition;
    } else {
      this.errorState = true;
      this.errors = {
        error: "System not found."
      };
    }
  }
  async computeJob() {
    const token = await this.$auth.getTokenSilently();
    const response = await Jobs.compute(token, this.jobId);
    if (response.ok) {
      this.loadJob();
    } else {
      console.log("Could not compute");
    }
  }
}
