
















































































































import { Component, Vue, Prop, Watch } from "vue-property-decorator";
import { System } from "@/types/System";
import FieldMapper from "@/components/jobs/FieldMapper.vue";

import { CSVHeader } from "@/utils/mapToCSV";

interface HTMLInputEvent extends Event {
  target: HTMLInputElement & EventTarget;
}
Vue.component("field-mapper", FieldMapper);

@Component
export default class CSVMapper extends Vue {
  @Prop() headers!: Array<CSVHeader>;
  @Prop() granularity!: string;
  @Prop() system!: System;
  @Prop() data_objects!: Array<Record<string, any>>;
  @Prop() indexField!: string;
  mapping!: Record<string, string>;
  componentValidity!: Record<string, boolean>;
  usedHeaders!: Array<number>;
  isValid!: boolean;
  indexHeader!: CSVHeader | null;

  data() {
    return {
      mapping: {},
      componentValidity: {},
      usedHeaders: [],
      isValid: false,
      indexHeader: null,
      dataObjectDisplay: this.initDataObjectDisplay()
    };
  }
  get toMap() {
    /* Create an array containing objects with a data object and metadata
     * object.
     * - data_object: Object - Data object from the api.
     * - metadata: System | Inverter | PVArray - metadata of the object
     *     to be mapped.
     */
    if (this.granularity == "system") {
      return this.data_objects.map(obj => {
        return {
          data_object: obj,
          metadata: this.system
        };
      });
    } else if (this.granularity == "inverter") {
      return this.data_objects.map(obj => {
        // get the second element of the location, due to "" first element
        const index = parseInt(obj.definition.schema_path.split("/")[2]);
        return {
          data_object: obj,
          metadata: this.system.inverters[index]
        };
      });
    } else {
      return this.data_objects.map(obj => {
        // splitting on "/" results in empty first element, so slice out
        const loc_array = obj.definition.schema_path.split("/").slice(1);
        const arr_index = parseInt(loc_array[loc_array.length - 1]);
        const inv_index = parseInt(loc_array[1]);
        return {
          data_object: obj,
          metadata: {
            parent: this.system.inverters[inv_index],
            ...this.system.inverters[inv_index].arrays[arr_index]
          }
        };
      });
    }
  }
  get required() {
    return this.data_objects[0].definition.data_columns;
  }
  useHeader(header: CSVHeader) {
    this.usedHeaders.push(header.header_index);
  }
  freeHeader(header: CSVHeader | null) {
    if (header) {
      this.usedHeaders.splice(this.usedHeaders.indexOf(header.header_index), 1);
    }
  }
  updateMapping(newMap: any) {
    // pop the index from the mapping
    const loc = newMap.loc;
    newMap = { ...newMap };
    newMap[this.indexField] = { csv_header: this.indexHeader };
    delete newMap["loc"];
    this.mapping[loc] = newMap;
    this.checkValidity();
    this.emitMapping();
  }
  emitMapping() {
    const mapObject = {
      mapping: this.mapping,
      complete: false
    };
    if (this.isValid) {
      mapObject.complete = true;
    }
    this.$emit("new-mapping", mapObject);
  }
  checkValidity() {
    // Check that all child components are completely mapped.
    const componentValidity: Record<string, boolean> = {};

    for (const ref in this.$refs) {
      // @ts-expect-error
      componentValidity[ref] = this.$refs[ref][0].isValid();
    }
    this.componentValidity = componentValidity;
    this.isValid =
      Object.values(componentValidity).every(x => x === true) &&
      this.indexMapped;
  }
  refName(index: number) {
    // Create a unique ref name for a nested component. Used to store
    // references to nested components for checking that all mappings are
    // valid and complete.
    return `${this.granularity}_${index}`;
  }
  initDataObjectDisplay() {
    const visibleMap: Record<string, boolean> = {};
    this.data_objects.map((x: any, i: number) => {
      visibleMap[this.refName(i)] = !x.definition.present;
    });
    return visibleMap;
  }
  get indexMapped() {
    return this.indexHeader != null;
  }
  mapIndex(event: any) {
    this.freeHeader(this.indexHeader);
    const index = event.target.value;
    const indexHeader = this.headers[index];
    this.indexHeader = indexHeader;

    this.useHeader(this.indexHeader);
    for (const dataObject of this.data_objects) {
      const loc = dataObject.definition.schema_path;
      const indexMapping = { csv_header: this.indexHeader };
      // update the index field or create a mapping
      if (loc in this.mapping) {
        // @ts-expect-error
        this.mapping[loc][this.indexField] = indexMapping;
      } else {
        const fieldMapping: Record<string, Record<string, CSVHeader>> = {};
        fieldMapping[this.indexField] = indexMapping;
        // @ts-expect-error
        this.mapping[loc] = fieldMapping;
      }
    }
    this.checkValidity();
    this.emitMapping();
  }
  @Watch("headers", { deep: true })
  resetMapping() {
    this.indexHeader = null;
    this.usedHeaders = [];
    this.mapping = {};
  }
}
