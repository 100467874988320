import { render, staticRenderFns } from "./DataParamHandler.vue?vue&type=template&id=f1d09990&scoped=true&"
import script from "./DataParamHandler.vue?vue&type=script&lang=ts&"
export * from "./DataParamHandler.vue?vue&type=script&lang=ts&"
import style0 from "./DataParamHandler.vue?vue&type=style&index=0&id=f1d09990&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f1d09990",
  null
  
)

export default component.exports