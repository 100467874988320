var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"csv-preview"},[_c('b',[_vm._v("CSV Preview")]),_vm._m(0),_c('div',{staticClass:"table-container"},[_c('table',{staticClass:"striped-table csv-mapping-preview",style:("--numCol: " + _vm.numCol)},[_c('thead',[_c('tr',[_vm._m(1),_vm._l((_vm.headers),function(header,i){return _c('th',{key:i,class:{
              hovered: _vm.currentlySelected == header,
              mapped: _vm.mapping[header.header] || _vm.mapping[header.header_index]
            }},[_vm._v(" "+_vm._s(header.header ? header.header : ("Column " + (header.header_index + 1)))+" ")])})],2)]),_c('tbody',[_c('tr',[_vm._m(2),_vm._l((_vm.headers),function(header,j){return _c('td',{key:j,class:{
              mapped: _vm.mapping[header.header] || _vm.mapping[header.header_index]
            }},[(_vm.mapping[header.header] || _vm.mapping[header.header_index])?[_vm._v(" "+_vm._s(_vm.mapping[header.header] ? _vm.displayName(_vm.mapping[header.header]) : _vm.displayName(_vm.mapping[header.header_index]))+" ")]:[_vm._v(" Not Mapped ")]],2)})],2),_vm._l((_vm.csvData),function(row,i){return _c('tr',{key:i},[_c('td',[_c('b',[_vm._v("Data Row "+_vm._s(i + 1))])]),(Array.isArray(row))?_vm._l((_vm.headers),function(header,j){return _c('td',{key:j,class:{
                mapped: _vm.mapping[header.header_index]
              }},[_vm._v(" "+_vm._s(row[header.header_index])+" ")])}):_vm._l((_vm.headers),function(header,j){return _c('td',{key:j,class:{
                mapped: _vm.mapping[header.header]
              }},[_vm._v(" "+_vm._s(row[header.header])+" ")])})],2)})],2)])])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('p',[_vm._v(" Below are the first 5 rows of data from your csv, with the variable each column is mapped to in the "),_c('i',[_vm._v("Mapped Variable")]),_vm._v(" row. ")])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('th',[_c('b',[_vm._v("CSV Headers")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('td',[_c('b',[_vm._v("Mapped Variable")])])}]

export { render, staticRenderFns }