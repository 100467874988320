









































import { Component, Vue, Prop, Watch } from "vue-property-decorator";
import { CSVHeader } from "@/utils/mapToCSV";

@Component
export default class SingleMapping extends Vue {
  @Prop() variable!: string;
  @Prop() headers!: Array<CSVHeader>;
  @Prop() usedHeaders!: Array<number>;
  selected!: string;
  units!: string;

  data() {
    return {
      selected: "Not included",
      units: "W"
    };
  }
  get isPower() {
    return this.variable.includes("performance");
  }
  emitMapping() {
    const mapping = {
      variable: this.variable,
      csv_header: this.selected
    };
    if (this.isPower) {
      // @ts-expect-error
      mapping.units = this.units;
    }
    this.$emit("new-mapping", mapping);
  }
  @Watch("headers")
  reset() {
    this.selected = "Not included";
    this.units = "W";
  }
}
