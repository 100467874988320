



















































































import { Component, Vue, Prop } from "vue-property-decorator";

import { System } from "@/types/System";
import { modelSpecs } from "@/types/ModelSpecification";
import {
  SandiaInverterParameters,
  PVWattsInverterParameters
} from "@/types/InverterParameters";
import { CECModuleParameters } from "@/types/ModuleParameters";

import SystemView from "@/components/model/System.vue";
import FileUpload from "@/components/FileUpload.vue";

import downloadFile from "@/utils/downloadFile";

Vue.component("system-view", SystemView);
Vue.component("file-upload", FileUpload);

@Component
export default class SystemSpec extends Vue {
  @Prop({ default: null }) systemId!: number | null;
  system!: System;
  model!: string;
  loading!: boolean;
  errorState!: boolean;
  apiErrors!: Record<string, any>;
  specValid!: boolean;

  created() {
    if (Object.keys(this.$store.state.systems).length == 0) {
      // If there are no loaded systems, try to load them for system validation
      this.$store.dispatch("fetchSystems");
    }
    if (this.systemId != undefined) {
      this.loadSystem();
    } else {
      this.system = new System({});
      this.loading = false;
    }
  }
  data() {
    return {
      system: this.system,
      modelPresetOptions: {
        pvsyst: "pvsyst-like",
        pvwatts: "pvwatts",
        sam: "SAM"
      },
      model: "sam",
      displaySummary: false,
      displayAdvanced: false,
      loading: false,
      errorState: false,
      apiErrors: {},
      specValid: false
    };
  }
  uploadSuccess(fileMetadata: string) {
    const metadata = JSON.parse(fileMetadata);
    const system = new System(metadata);
    this.system = system;
    this.inferModel();
  }

  /* istanbul ignore next */
  downloadSystem() {
    const contents = new Blob([JSON.stringify(this.system, null, 2)], {
      type: "application/json;charset=utf-8;"
    });
    const filename = `${this.system.name}.json`;
    downloadFile(filename, contents);
  }

  get modelSpec() {
    return modelSpecs[this.model];
  }

  inferModel() {
    if (this.system.inverters.length > 0) {
      const firstInverter = this.system.inverters[0];
      if (
        SandiaInverterParameters.isInstance(firstInverter.inverter_parameters)
      ) {
        if (firstInverter.arrays.length > 0) {
          const firstArray = firstInverter.arrays[0];
          if (CECModuleParameters.isInstance(firstArray.module_parameters)) {
            this.model = "sam";
          } else {
            this.model = "pvsyst";
          }
        }
      } else if (
        PVWattsInverterParameters.isInstance(firstInverter.inverter_parameters)
      ) {
        this.model = "pvwatts";
      }
    }
  }
  async loadSystem() {
    this.loading = true;
    const token = await this.$auth.getTokenSilently();
    const response = await fetch(`/api/systems/${this.systemId}`, {
      headers: new Headers({
        Authorization: `Bearer ${token}`
      })
    });
    if (response.ok) {
      const system = await response.json();
      this.system = new System(system.definition);
      this.inferModel();
      this.loading = false;
    } else {
      this.errorState = true;
      this.apiErrors = {
        error: "System not found."
      };
      this.loading = false;
    }
  }
  async saveSystem() {
    if (this.specValid) {
      const token = await this.$auth.getTokenSilently();
      let apiPath = "/api/systems/";
      if (this.systemId) {
        apiPath = apiPath + this.systemId;
      }
      const response = await fetch(apiPath, {
        method: "post",
        body: JSON.stringify(this.system),
        headers: new Headers({
          Authorization: `Bearer ${token}`
        })
      });
      if (response.ok) {
        this.$router.push("/systems");
      } else {
        this.loading = false;
        this.errorState = true;
        try {
          this.apiErrors = await response.json();
        } catch (error) {
          this.apiErrors = {
            error: `API responded with status code: ${response.status}`
          };
        }
      }
    }
  }
  updateSpecValidity(isValid: boolean) {
    this.specValid = isValid;
  }
}
